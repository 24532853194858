import React, { useEffect } from 'react'
import TableLG from './_tableLG'
import TableMobile from './_tableMobile'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG } from 'src/styles/breakpoints'
import { HeroPropsCripto } from '../../assets/types'
import { initTheme, Theme } from '@interco/inter-ui'

function AcompanheCotacoes ({ setIsOpen, deepLink }: HeroPropsCripto) {
  const width = useWidth()

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <>
      {width >= WIDTH_LG
      ? <TableLG setIsOpen={setIsOpen} /> : <TableMobile deepLink={deepLink} />
    }
    </>
  )
}

export default AcompanheCotacoes
