import React from 'react'

import FAQ from 'src/components/Faq/index'

type Faq = {
  faq: {question: string; answer: string}[];
}

const DuvidasFrequentesCripto = ({ faq }: Faq) => {
  return (
    <section className='pb-5 pt-md-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-8 offset-lg-2 mb-4 text-md-center'>
            <h2 className='font-sora fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-3'>
              Dúvidas frequentes sobre o Inter Cripto
            </h2>
            <p className='text-grayscale--400 fs-16 lh-20 fs-md-18 lh-md-22 mb-4'>
              Se você tem dúvidas como investir em criptomoedas, veja se conseguimos te ajudar
            </p>
          </div>
          <FAQ answerData={faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} />
        </div>
      </div>
    </section>
  )
}

export default DuvidasFrequentesCripto
