import React, { useState, useEffect } from 'react'
import axios from 'axios'
import slugify from 'slugify'
import * as URLs from 'config/api/Urls'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { WIDTH_MD } from 'src/styles/breakpoints'

import SectionComponent from 'src/components/SectionComponent'
import CriptoMoedas from 'src/components/UI/Charts/CriptoMoedas/_index'

import { CotacoesMoedasProps, HeroPropsCripto } from '../../assets/types'

import { Button } from './style'

const GraficoBitcoin = ({ setIsOpen, deepLink }: HeroPropsCripto) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const [ periodoSelecionado, setPeriodoSelecionado ] = useState<CotacoesMoedasProps[]>([])
  const dataCotacoes = periodoSelecionado.map((item: CotacoesMoedasProps) => item)
  const [ filterPeriodo, setFilterPeriodo ] = useState('UM_DIA')

  async function getFilter () {
    const response = await axios.get(
      `${URLs.WEB_API_LP_CRIPTO}/api/v1/cripto`,
    )
    setPeriodoSelecionado(response.data.cotacoes)
  }

  useEffect(() => {
    getFilter()
    setFilterPeriodo(filterPeriodo)
  }, [ filterPeriodo ])
  return (
    <>
      {dataCotacoes.map((item: CotacoesMoedasProps) => (
        <SectionComponent
          key={item.nome}
          id={'criptomoeda-' + slugify(item.nome.replace(/\./g, ''), { lower: true })}
          SectionStyles={`py-5 ${item.id === 'ETH' ? 'bg-grayscale--100' : 'bg-white'}`}
          minHeight={{ sm: '643px', md: '405px', lg: '453px', xl: '517px' }}
        >
          <>
            <div className={`col-12 col-md-6 col-lg-5 mb-4 mb-md-0 ${item.id === 'ETH' ? '' : 'order-md-last'}`}>
              <div className='d-flex align-content-center mb-3'>
                <div className='mr-2 mr-lg-3'>
                  <img
                    src={item.imagem.url} alt='Ícone de Seleção de moeda'
                    width={width <= WIDTH_MD ? 24 : 40}
                  />
                </div>
                <h3 className='font-sora text-grayscale--500 fs-24 lh-30 lh-lg-40 fs-xl-40 lh-xl-50 fs-lg-32 fw-600'>
                  {item.nome} {`(${item.sigla})`}
                </h3>
              </div>
              <p
                className='text-grayscale--400 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 mb-0 mb-md-4'
                dangerouslySetInnerHTML={{ __html: item.moedaInfo }}
              />
              {
              width >= WIDTH_MD &&
                <Button
                  title={`${'Comprar ' + item.nome}`}
                  className='btn btn--lg bg-orange--base text-white text-none mt-md-2'
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: item.id === 'BTC' ? 'dobra_04' : 'dobra_05',
                      section_name: item.nome,
                      element_action: 'click button',
                      element_name: 'Comprar ' + item.nome,
                    })
                  }}
                >
                  Comprar {item.nome}
                </Button>
              }
            </div>
            <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
              <CriptoMoedas
                items={item}
                selectedCurrency={item.id}
                filterPeriodo={filterPeriodo}
              />
            </div>
            {
            width < WIDTH_MD &&
              <div className='col-12'>
                <a
                  href={deepLink}
                  title={`${'Comprar ' + item.nome}`}
                  className='btn btn--lg bg-orange--base text-white text-none'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: item.id === 'BTC' ? 'dobra_04' : 'dobra_05',
                      section_name: item.nome,
                      element_action: 'click button',
                      element_name: 'Comprar ' + item.nome,
                    })
                  }}
                >
                  Comprar {item.nome}
                </a>
              </div>
            }
          </>
        </SectionComponent>
        ))}
    </>
  )
}

export default GraficoBitcoin
