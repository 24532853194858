import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Button = styled.button`
  
  @media ${device.tablet} {
    max-width: 264px;
  }
`

export const LinkButton = styled.a`
  
  @media ${device.tablet} {
    max-width: 264px;
  }
`
