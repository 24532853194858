
import React from 'react'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import SectionComponent from 'src/components/SectionComponent'

import pageText from '../../assets/data/container-blog.json'

import { orange } from 'src/styles/colors'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { BlogArticleType } from './types'
import { BlogCarousel } from './style'

const AcompanheOblogSobreCripto = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='acompanhe-o-blog-inter-invest'
      SectionStyles='pt-5 pb-0 bg-white align-items-md-start'
      ContainerStyles='pt-lg-5'
      minHeight={{ sm: '1348px', md: '701px', lg: '778px', xl: '838px', xxl: '838px' }}
    >
      <div className='col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 mb-4 mb-md-3'>
        <h2 className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 text-md-center mb-md-4'>
          {pageText.title}
        </h2>
        <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 text-md-center mt-4 mt-md-2'>
          {pageText.description}
        </p>
      </div>
      {
        pageText.blogContent.map((blogArticleContent: BlogArticleType, index: number) => (
          <BlogCarousel key={index} className='col-12 col-md-4 p-card mt-md-2 mb-4'>
            <a
              data-blog={blogArticleContent.title}
              href={blogArticleContent.link}
              title={`Leia mais sobre ${blogArticleContent.title}`}
              target='_blank' rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  section_name: pageText.title,
                  element_action: 'click button',
                  element_name: blogArticleContent.title,
                  redirect_url: blogArticleContent.link,
                })
              }}
            >
              <article className='col-12 px-0 align-items-center d-flex flex-column'>
                <img src={blogArticleContent.image} alt={blogArticleContent.alt} style={{ maxWidth: '100%' }} />
                <div className='col-12 pt-3 px-3 px-md-2 px-lg-4 mt-n5 bg-white content'>
                  <span className='font-sora fs-12 lh-15 fw-600 text-white bg-orange--extra category'>{blogArticleContent.categoria}</span>
                  <span className='font-sora fs-12 lh-15 fw-600 text-grayscale--500 bg-gray-100 tag'>{blogArticleContent.tag}</span>
                  <h3
                    className='font-sora fs-16 lh-20 fs-md-20 lh-md-25 fw-600 mt-3 mb-2 mb-md-0 text-grayscale--500'
                    dangerouslySetInnerHTML={{ __html: blogArticleContent.title }}
                  />
                  <span className='fs-12 lh-15 text-grayscale--300 fw-600'>{blogArticleContent.data}</span>
                  {width >= WIDTH_MD &&
                    <>
                      {width > WIDTH_MD
                        ? <p className='fs-16 lh-20 mb-2 fw-400 mt-3 text-grayscale--500'>{blogArticleContent.description}</p>
                        : <p className='fs-16 lh-20 mb-2 fw-400 mt-3 text-grayscale--500'>{blogArticleContent.descriptionMD}</p>
                      }
                      <div className='text-md-right'>
                        <span className='fs-14 lh-17 fw-400 text-orange--extra mt-xl-3 mr-lg-2'>{pageText.readMore}</span>
                        <OrangeDsIcon size='MD' icon='arrow-right' color={orange.extra} />
                      </div>
                    </>
                  }
                </div>
              </article>
            </a>
          </BlogCarousel>
        ))
      }
    </SectionComponent>
  )
}

export default AcompanheOblogSobreCripto
