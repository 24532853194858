import React, { useState, useEffect, useLayoutEffect } from 'react'
import axios from 'axios'
import * as URLs from 'config/api/Urls'
import { convertFloatToCurrencyString } from 'src/shared/helpers'
import ChevronIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG, WIDTH_MD, WIDTH_XL, WIDTH_XXL } from 'src/styles/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { numberColor, ArrowPosition } from './toggle'

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

import { FiltrosProps, GraficoProps, VariacoesProps, ICriptoMoedasProps } from 'src/pages/pra-voce/investimentos/criptomoedas/assets/types'

import { SelectPeriodo } from './style'

export default function CriptoMoedas ({ items, filterPeriodo, selectedCurrency }: ICriptoMoedasProps) {
  const width = useWidth(300)
  const [ isWidth, setIsWidth ] = useState(287)
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isIndex, setIsIndex ] = useState(0)
  const [ errorMessage, setErrorMessage ] = useState('Nenhuma base de dados encontrada!')
  const [ isFilter, setIsFilter ] = useState(filterPeriodo)

  const isDataLayers = items.nome
  const dataGraficoCorLinha = items.graficoCompleto.grafico.corLinha
  const dataGrafico = items.graficoCompleto.grafico.variacoes.map((item: GraficoProps) => item)
  const [ isDataGrafico, setIsDataGrafico ] = useState(dataGrafico)

  async function getFilter (filter?: string) {
    const config = {
      body: {
        periodo: filter ?? isFilter,
        moedas: [
          selectedCurrency,
        ],
      },
    }

    try {
      const response = await axios.post(
        `${URLs.WEB_API_LP_CRIPTO}/api/v1/cripto`, config.body,
        )
        selectedCurrency === 'BTC' ? setIsDataGrafico(response.data.BTC.grafico.variacoes)
        : setIsDataGrafico(response.data.ETH.grafico.variacoes)
      } catch (error) {
      setErrorMessage(errorMessage)
    }
  }

  const HandleClick = (id: string, index: number) => {
    setIsIndex(index)
    getFilter(id)
  }

  const data = isDataGrafico.map((item: VariacoesProps) => (
    {
      name: item.legenda,
      R$: item.valor,
      amt: item.valorDescricao,
    }
  ))

  useEffect(() => {
    setIsFilter(isFilter)
  }, [ isFilter, filterPeriodo ])

  useLayoutEffect(() => {
    if (width < WIDTH_MD) {
      setIsWidth(330)
    } else if (width >= WIDTH_MD && width < WIDTH_LG) {
      setIsWidth(360)
    } else if (width >= WIDTH_LG && width < WIDTH_XL) {
      setIsWidth(405)
    } else if (width >= WIDTH_XL && width <= WIDTH_XXL) {
      setIsWidth(480)
    } else {
      setIsWidth(600)
    }
  }, [ width ])

  return (
    <>
      <div className='col-12 px-0'>
        <span className='fs-14 lh-17 text-grayscale--400'>Preço atual do {items.nome}</span>
        <div className='font-sora text-grayscale--500 mb-4'>
          <span className='fs-20 lh-25 fw-600 mr-2'>R$ {convertFloatToCurrencyString(items.preco)}</span>
          <ChevronIcon size='SM' color={numberColor(items.variacaoMoeda)} icon={ArrowPosition(items.variacaoMoeda)} />
          <span className='fs-10 lh-12 ml-1' style={{ color: numberColor(items.variacaoMoeda) }}>
            {items.variacaoMoeda}
          </span>
        </div>
      </div>

      <SelectPeriodo>
        {items.graficoCompleto.filtros.map((periodo: FiltrosProps, index: number) => (
          <div
            key={index}
            title={`Filtrar ${periodo.texto}`}
            className='fs-10 lh-12 fs-md-12 lh-md-17 '
            onClick={() => {
              HandleClick(periodo.id, index)
              sendDatalayerEvent({
                section: isDataLayers === 'Bitcoin' ? 'dobra_04' : 'dobra_05',
                section_name: isDataLayers,
                element_action: 'click button',
                element_name: periodo.texto,
              })
            }}
            style={{
              color: `${isIndex === index ? '#ffffff' : ''}`,
              background: `${isIndex === index ? '#FF7A00' : ''}`,
            }}
          >{periodo.texto}
          </div>
        ))}
      </SelectPeriodo>

      <ResponsiveContainer width={isWidth} height={230} className='fs-10 lh-12'>
        <LineChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 25,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke='#DEDFE4' vertical={false} />
          <XAxis dataKey='name' tickLine={false} axisLine={false} />
          <YAxis
            yAxisId='right' orientation='right' axisLine={false}
            tickLine={false} domain={[ 'auto', 'auto' ]}
          />
          <Tooltip />
          <Line yAxisId='right' type='monotone' dataKey='R$' className='teste' stroke={dataGraficoCorLinha} stroke-width='3' />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}
