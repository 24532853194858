import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import CarouselAcesseOappJSON from './../../assets/data/carousel-como-investir-em-cripto.json'

import { ContainerCarousel } from './styles'

type CarouselAcesseOappProps = {
  subTitle: string;
  description: string;
  title: string;
  image: string;
  altText: string;
}

const ComoInvestirEmCripto = () => {
  return (
    <SectionComponent
      id='como-investir-em-cripto'
      SectionStyles='pt-3 pb-5 bg-grayscale--100'
      minHeight={{ sm: '775px', md: '555px', lg: '659px', xl: '837px' }}
    >
      <ContainerCarousel className='col-12'>
        <DefaultCarousel
          sm={{ items: 1 }}
          md={{ items: 1 }}
          lg={{ items: 1 }}
          xl={{ items: 1 }}
        >
          {CarouselAcesseOappJSON.map((item: CarouselAcesseOappProps, index: number) => (
            <div className='row' key={index}>
              <div className='col-12 col-md-6 col-lg-5'>
                {item.subTitle && item.description !== '' &&
                  <h2
                    className='font-sora text-grayscale--500 fs-16 lh-20 fs-lg-20 lh-lg-25 fs-xl-24 lh-xl-30 mb-3'
                    dangerouslySetInnerHTML={ { __html: item.subTitle } }
                  />
                }
                <h3
                  className='font-sora text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-3'
                  dangerouslySetInnerHTML={ { __html: item.title } }
                />
                <p
                  className='text-grayscale--400 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 mb-3 mb-md-4 pr-xl-5 mb-4'
                  dangerouslySetInnerHTML={ { __html: item.description } }
                />
              </div>
              <div className='col-12 col-md-6 col-lg-5'>
                <img
                  src={item.image}
                  alt={item.altText}
                  className='float-lg-right'
                  style={{ maxWidth: '100%' }}
                />
              </div>
            </div>
          ))}
        </DefaultCarousel>
      </ContainerCarousel>
    </SectionComponent>
  )
}

export default ComoInvestirEmCripto
