import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'

import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import HeroInvestirEmCripto from './sections/hero-investir-em-cripto/_index'
import AcompanheAsCotacoes from './sections/acompanhe-as-cotacoes/_index'
import AmelhorSelecaoDeMoedas from './sections/a-melhor-selecao-de-moedas/_index'
import GraficoMoedas from './sections/grafico-moedas/_index'
import VantagensDeInvestir from './sections/vantagens-de-investir/_index'
import ComoInvestirEmCripto from './sections/como-investir-em-cripto/_index'
import AcompanheOblogSobreCripto from './sections/acompanhe-o-blog-cripto/_index'
import DuvidasFrequentesCripto from './sections/duvidas-frequentesCripto/_index'

import QRCodeCripto from './assets/images/qrcode-criptomoedas.png'

import pageContext from './pageContext.json'
import useDomReady from 'src/hooks/window/useDomReady'

const CryptoMoedas = () => {
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)
  const deepLink = 'https://intergo.app/a9d6235f'

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-24 lh-30">Invista em criptomoedas</span>'
        instructions='Abra a câmera do seu celular e aponte para o <strong class="text-grayscale--500">QR Code</strong> e invista na moeda selecionada.'
        qrCode={QRCodeCripto}
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openModal}
      <HeroInvestirEmCripto setIsOpen={setIsOpen} deepLink={deepLink} />
      <AcompanheAsCotacoes setIsOpen={setIsOpen} deepLink={deepLink} />
      <AmelhorSelecaoDeMoedas />
      <GraficoMoedas setIsOpen={setIsOpen} deepLink={deepLink} />
      <VantagensDeInvestir setIsOpen={setIsOpen} deepLink={deepLink} />
      <ComoInvestirEmCripto />
      <AcompanheOblogSobreCripto />
      <DuvidasFrequentesCripto faq={pageContext.structuredData.faq} />
    </Layout>
  )
}

export default CryptoMoedas
