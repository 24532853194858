
import React from 'react'
import IntercoOrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import VantagensDeInvestirJSON from '../../assets/data/vantagens-de-investir.json'

import { ContentType, ItemProps } from './types'
import { HeroPropsCripto } from '../../assets/types'

import { ContainerReinvista, Button } from './style'

const VantagensDeInvestir = ({ setIsOpen, deepLink }: HeroPropsCripto) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()
  const { title, description, cta, cards }: ContentType = VantagensDeInvestirJSON

  return (
    <SectionComponent
      id='reinvista-o-seu-cashback'
      SectionStyles='py-5 bg-white'
      RowStyles='align-items-center justify-content-between'
      minHeight={{ sm: '623px', md: '416px', lg: '464px', xl: '528px' }}
    >
      <div className='col-12 col-md-6 col-lg-5'>
        <h2
          className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0 mb-lg-2 mb-xl-3'
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {width >= WIDTH_MD &&
          <Button
            title={cta.text}
            className='btn btn-orange--extra btn--lg fw-600 text-none mt-4'
            dangerouslySetInnerHTML={{ __html: cta.text }}
            onClick={() => {
              setIsOpen(true)
              sendDatalayerEvent({
                section: 'dobra_06',
                section_name: title,
                element_action: 'click button',
                element_name: cta.text,
              })
            }}
          />
        }
      </div>
      <ContainerReinvista className='col-12 col-md-6'>
        <DefaultCarousel
          sm={{ items: 1, partialVisibilityGutter: 10 }}
          md={{ items: 1, partialVisibilityGutter: 10 }}
          lg={{ items: 1, partialVisibilityGutter: 100 }}
          xl={{ items: 1, partialVisibilityGutter: 177 }}
        >
          {cards.map((item: ItemProps, index: number) => (
            <div
              key={index}
              title={item.title}
              className='card-carousel mr-3 d-flex flex-column'
            >
              <div className='mb-2'><IntercoOrangeIcon size='MD' color='#161616' icon={item.icon} /></div>
              <h3 className='font-sora fs-16 lh-20 text-grayscale--500 fw-600'>{item.title}</h3>
              <p
                className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--500 mb-0'
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>
            ),
          )}
        </DefaultCarousel>
        {width < WIDTH_MD &&
          <a
            href={deepLink}
            title={cta.text}
            className='btn btn-orange--extra btn--lg fw-600 text-none mt-4'
            dangerouslySetInnerHTML={{ __html: cta.text }}
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_06',
                section_name: title,
                element_action: 'click button',
                element_name: cta.text,
                redirect_url: deepLink,
              })
            }}
          />
        }
      </ContainerReinvista>
    </SectionComponent>
  )
}

export default VantagensDeInvestir
