import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const ListOfCoins = styled.div`
  &.row {
    padding: 16px 0;
    border-bottom: 1px solid ${grayscale[200]};

    @media ${device.tablet} { 
      border-radius: 8px;
      border: 1px solid ${grayscale[200]};
      margin-bottom: 16px;
    }
  }
`

export const LinkInvestir = styled.a`
  padding: 10px;
  border-radius: 8px;
`

export const TableWrapper = styled.div`
  width: 100%;
  --table-body-cell-font-size: 12px;
  --table-row-height: fit-content;
  --table-row-padding: 16px 5px;

  > div {
    display: flex;
  flex-direction: column;
  align-items: center;
  }

  > div > div > div:first-of-type {
    display: none;
  }
  > div > div > div:last-of-type {
    display: none;
  }
`

export const ButtonInvestir = styled.button`

  @media ${device.tablet} { 
    width: 100%;
    height: 32px;
    border: 0;
    border-radius: 8px;
    max-width: 74px;
  }
`

export const VariacaoModal = styled.div`

  @keyframes fadeIn {
    0% { opacity: 0.1; }
    100% { opacity: 1; }
  }

  @keyframes animated {
    from {
      top: 0;
    }
    to {
      bottom: -292px;
    }
  }

  padding: 12px 24px 24px 24px;  
  background: ${white};
  box-shadow: 0px 2px 8px 8px rgba(22, 22, 22, 0.08);
  position: fixed;
  bottom: 0;
  border-radius: 8px 8px 0 0;
  z-index: 10;
  height: 292px;

  &.open {
    transition: opacity 0.3s ease-in-out 0s;
    animation: fadeIn 0.3s ease-in-out forwards;
  }

  &.close {
    transition: opacity 0.3s ease-in-out 0s;
    animation: animated 0.3s ease-in-out forwards;
  }

  div {
    width: 48px;
    height: 4px;
    background: ${grayscale[200]};
    position: relative;
    margin: 12px auto 28px auto;
    cursor: pointer;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid ${grayscale[200]};
      padding: 16px 16px 16px 0;
      height: 56px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
`
