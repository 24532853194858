import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const BlogCarousel = styled.div`

  .content {
    border-radius: 16px 24px 0 0;
    height: 150px;

    @media ${device.tablet} { 
      top: 31px;
      height: 210px;
    }
    @media ${device.desktopLG} { 
      height: 276px;
    }
  }

  .category {
    height: 16px;
    border-radius: 4px;
    padding: 2px 4px;
    width: 150px;
    margin-right: 16px;

    .tag {
      border-radius: 4px;
      padding: 2px 4px;
      width: 79px;
    }
  }

  &.p-card {
    padding: 0;
  
    @media ${device.tablet} { 
      padding-right: 24px;

      &:last-child {
        padding-right: 0;
      }
    }
    @media ${device.desktopLG} { 
      padding-right: 10.5px;
      padding-left: 10.5px
    }     
  }

  article {
    display: grid;
    min-height: 350px;

    @media ${device.tablet} { 
      min-height: 520px;
    }
    @media ${device.desktopLG} { 
      min-height: 510px;
    }
  }
`
