import React from 'react'
import SectionComponent from 'src/components/SectionComponent'

const AmelhorSelecaoDeMoedas = () => {
  return (
    <SectionComponent
      id='acompanhe-as-cotacoes'
      SectionStyles='py-5 bg-grayscale--100'
      RowStyles='justify-content-md-center'
      minHeight={{ sm: '246px', md: '169px', lg: '271px', xl: '361px' }}
    >
      <div className='col-12 col-lg-8 text-md-center'>
        <h2 className='font-sora text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 mb-3'>
          A melhor seleção de moedas digitais do Inter Cripto
        </h2>
        <p className='text-grayscale--400 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 mb-0 px-md-5'>
          Conheça as principais criptomoedas que estão disponíveis para investimento em nossa plataforma.
        </p>
      </div>
    </SectionComponent>
  )
}

export default AmelhorSelecaoDeMoedas
