import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Calculator = styled.div`
 
  strong {
    margin-bottom: 8px;
  }
`

export const Select = styled.div`

  @keyframes fadeIn {
    0% { opacity: 0.1; }
    100% { opacity: 1; }
  }

  @keyframes animated {
    from {
      top: 0;
    }
    to {
      bottom: -200px;
    }
  }

  display: flex;
  border-radius: 16px;
  border: 1px solid ${grayscale[200]};
  padding: 0 0 0 10px;
  align-items: center;
  background: ${white};
  min-height: 72px;

  input {
    width: 100%;  
    border: 0;
    
    :disabled {
      background: none;
    }
    ::-webkit-input-placeholder { color: ${grayscale[200]}; } // (Chrome, Safari, Opera)
    :-moz-placeholder { color: ${grayscale[200]}; } // (Firefox 18 ou inferior)
    ::-moz-placeholder { color: ${grayscale[200]}; } // (Firefox 19 ou superior)
    :-ms-input-placeholder { color: ${grayscale[200]}; } // (Internet Explorer)
  }
`

export const ListOfCoins = styled.ul`
  list-style: none;
  padding: 10px 5px;
  margin-bottom: 0;
  width: 100%;
  min-height: 72px;
  z-index: 10;
  background: ${white};
  overflow: hidden;
  border-radius: 0 16px 16px 0;
  display: flex;
  align-items: center;

  &.active {
    display: block;
    border-left: 1px solid ${orange.extra};
    border-right: 1px solid ${orange.extra};
    border-bottom: 1px solid ${orange.extra};
    border-top: 1px solid ${grayscale[200]};
    border-radius: 0 0 16px 16px;
    min-height: 200px;
    position: absolute;
    transition: opacity 0.3s ease 0s;
    animation: fadeIn 0.3s ease-out forwards;

    li {
      margin-bottom: 4px;
    }
  }
  
  &.top-list {
    display: flex;
    border-radius: 0 16px 0 0;
    border-top: 1px solid ${orange.extra};
    border-left: 1px solid ${orange.extra};
    border-right: 1px solid ${orange.extra};
    border-bottom: 0;
  }

  li {
    cursor: pointer;
  }
`
