import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const SelectPeriodo = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 20px;
  border: 1px solid ${grayscale[100]};
  margin-top: 24px;
  margin-bottom: 16px;

  @media ${device.tablet} {
    gap: 11px;
  }
  @media ${device.desktopLG} {
    gap: 18px;
  }
  @media ${device.desktopXL} {
    gap: 30px;
  }
  @media ${device.desktopXXXL} {
    gap: 40px;
  }

  div {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: ${grayscale[400]};
    cursor: pointer;

    @media ${device.tablet} {
      width: 35px;
      height: 35px;
    }

    &.active {
      background: ${orange.extra};
      color: ${white};
      font-weight: 600;
    }
  }
`
