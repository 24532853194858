import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SectionComponent from 'src/components/SectionComponent'
import ChevronIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { convertFloatToCurrencyString } from 'src/shared/helpers'
import * as URLs from 'config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { WIDTH_SM } from 'src/styles/breakpoints'

import { numberColor, ArrowPosition } from './_toggle'

import { SectionMoedasProps, HeroPropsCripto } from '../../assets/types'

import { LinkInvestir, TableWrapper } from './style'

import { Table } from '@interco/inter-ui/components/Table'
import useWidth from 'src/hooks/window/useWidth'

const TableLG = ({ deepLink }: HeroPropsCripto) => {
  const [ periodoSelecionado, setPeriodoSelecionado ] = useState<SectionMoedasProps[]>([])
  const [ filterPeriodo, setFilterPeriodo ] = useState(1)
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isLoading, setLoading ] = useState(false)
  const width = useWidth()

  async function getFilter () {
    setLoading(true)
    const response = await axios.get(
      `${URLs.WEB_API_LP_CRIPTO}/api/v1/cripto`,
    )
    setLoading(false)
    setPeriodoSelecionado(response.data.moedas)
  }

  const data = periodoSelecionado.map((item: SectionMoedasProps) => item)

  const dataColumn = [
    {
      Header: 'Nome',
      maxWidth: width < WIDTH_SM ? 70 : '25%',
      accessor: 'nome',
      Cell: ({ row }) => (
        <>
          <div>
            <img
              src={row.original.imagem.url} alt='Ícone de Seleção de moeda'
              width={16} className='mr-1 mr-md-2'
            />
          </div>
          <div>
            <span className='d-block fw-600 fs-12 lh-15 fs-md-14 lh-md-17 text-grayscale--500'>{row.original.nome}</span>
            <span className='d-block fs-md-12 lh-md-14'>{row.original.sigla}</span>
          </div>
        </>
      ),
    },
    {
      Header: 'Preço',
      accessor: 'precoAtual',
      maxWidth: width < WIDTH_SM ? 120 : '60%',
      Cell: ({ row }) => (
        <div>
          <span>R$ {convertFloatToCurrencyString(row.original.precoAtual)}</span>
        </div>
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      maxWidth: width < WIDTH_SM ? 0 : '15%',
      disableSortBy: true,
      Cell: ({ row }) => (
        <LinkInvestir
          href={deepLink}
          className='fs-10 lh-12 bt bg-orange--extra text-white'
          onClick={() => {
          sendDatalayerEvent({
            section: 'dobra_02',
            section_name: 'Acompanhe as cotações das criptomoedas em tempo real',
            element_action: 'click button',
            element_name: 'Comprar',
            redirect_url: deepLink,
          })
          }}
        >
          Comprar
        </LinkInvestir>

      ),
    },
]

  useEffect(() => {
    getFilter()
    setFilterPeriodo(filterPeriodo)
  }, [ filterPeriodo ])

  return (
    <SectionComponent
      id='acompanhe-as-cotacoes'
      SectionStyles='py-5 bg-white'
      RowStyles='justify-content-md-center'
      minHeight={{ sm: '400px', md: '400px', lg: '400px', xl: '400px' }}
    >
      <div className='col-12 col-md-10 col-lg-8 text-md-center'>
        <h2 className='font-sora text-grayscale--500 fs-24 lh-30 lh-lg-40 fs-xl-40 lh-xl-50 fs-lg-32 fw-600 mb-3'>
          Acompanhe as cotações das criptomoedas em tempo real
        </h2>
      </div>
      <div className='col-12 col-lg-9 text-md-center px-lg-4'>
        <p className='text-grayscale--400 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 mb-4'>
          <span className='d-lg-block d-xl-inline'>Esteja atento ao mercado financeiro e tudo o que acontece com os investimentos</span> em criptoativos.
        </p>
      </div>

      <TableWrapper>
        <Table
          data={data}
          columns={dataColumn}
          maxHeight='auto'
          initialPageSize={10}
          rowsPerPageOptions={[ 5, 10, 15 ]}
          isLoading={isLoading}
          styleType='default'
          isExpanded
          onExpanded={(row) => (
            <div style={{ width: '100%' }}>
              <p className='fs-14 text-grayscale--400 fw-600'>Cotações</p>
              <div className='d-flex justify-content-between w-100'>
                <div>
                  <p className='fs-12 text-grayscale--400'>1h%</p>
                  <ChevronIcon size='SM' color={numberColor(row.original.variacao1h)} icon={ArrowPosition(row.original.variacao1h)} />
                  <span className='ml-1' style={{ color: numberColor(row.original.variacao1h) }}>
                    {convertFloatToCurrencyString(row.original.variacao1h)}%
                  </span>
                </div>
                <div>
                  <p className='fs-12 text-grayscale--400'>24h%</p>
                  <ChevronIcon size='SM' color={numberColor(row.original.variacao24h)} icon={ArrowPosition(row.original.variacao24h)} />
                  <span className='ml-1' style={{ color: numberColor(row.original.variacao24h) }}>
                    {convertFloatToCurrencyString(row.original.variacao24h)}%
                  </span>
                </div>
                <div>
                  <p className='fs-12 text-grayscale--400'>7d%</p>
                  <ChevronIcon size='SM' color={numberColor(row.original.variacao7d)} icon={ArrowPosition(row.original.variacao7d)} />
                  <span className='ml-1' style={{ color: numberColor(row.original.variacao7d) }}>
                    {convertFloatToCurrencyString(row.original.variacao7d)}%
                  </span>
                </div>
              </div>
            </div>
          )}
        />
      </TableWrapper>
    </SectionComponent>
  )
}

export default TableLG
