import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Link } from 'gatsby'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import headerJson from '../../assets/data/hero.json'

import CriptoCalculator from '../components/calculadora/_index'

import { HeroPropsCripto } from '../../assets/types'

import { Button, LinkButton } from './style'

const HeroInvestirEmCripto = ({ setIsOpen, deepLink }: HeroPropsCripto) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <SectionComponent
      id='invista-em-cripto-moedas'
      SectionStyles='bg-grayscale--100'
      minHeight={{ sm: '632px', md: '336px', lg: '460px', xl: '575px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
        <div className='bread'>
          <Link
            to='/'
            className='d-md-inline'
          ><OrangeIcon icon='home' color='#6A6E81' size='MD' />
          </Link>
          <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
          <Link
            to='/pra-voce/investimentos/'
            className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
          >
            {headerJson.breadcrumb[0]}
          </Link>
          <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
          <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
        </div>
        <h1 className='font-sora text-grayscale--500 fs-24 lh-30 lh-lg-40 fs-xl-40 lh-xl-50 fs-lg-32 fw-600 mb-3 mt-2'>
          <span className='d-block d-md-inline'>Negociar</span> criptomoedas no Inter é simples!
        </h1>
        <p className='text-grayscale--400 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 mb-0 mb-md-4'>
          Veja como é seguro comprar e vender Bitcoin, Ethereum e outros criptoativos no Inter
          Cripto investindo partir de R$ 1,00. Utilize a calculadora de cripto e descubra.
        </p>
        {width >= WIDTH_MD &&
          <Button
            title='Negociar Cripto'
            className='btn btn--lg bg-orange--base text-white text-none mt-md-2'
            onClick={() => {
              setIsOpen(true)
              sendDatalayerEvent({
                section: 'dobra_01',
                section_name: 'Negociar criptomoedas no Inter é simples!',
                element_action: 'click button',
                element_name: 'Negociar Cripto',
              })
            }}
          >
            Negociar Cripto
          </Button>
        }
      </div>
      <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
        <div className='row mx-0'>
          <CriptoCalculator />
        </div>
      </div>
      {width < WIDTH_MD &&
        <div className='col-12 col-md-6 col-lg-5'>
          <LinkButton
            href={deepLink}
            title='Investir em Cripto'
            className='btn btn--lg bg-orange--base text-white text-none mt-md-2'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_01',
                section_name: 'Negociar criptomoedas no Inter é simples!',
                element_action: 'click button',
                element_name: 'Investir em Cripto',
              })
            }}
          >
            Investir em Cripto
          </LinkButton>
        </div>
      }
    </SectionComponent>
  )
}

export default HeroInvestirEmCripto
