import styled from 'styled-components'
import { orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const ContainerCarousel = styled.div`

  .react-multi-carousel-track {
    padding-bottom: 40px;
    align-items: center;

    @media ${device.tablet} {
      padding-bottom: 0;
      padding-top: 40px;
    }
  }

  .react-multi-carousel-dot {
    button {
      background: ${orange.extra};
    }
    &--active {
      button {
        background: ${orange.extra};
      }
    }
  }
  
  .react-multiple-carousel__arrow {
    @media ${device.tablet} {
      bottom: 110px;
      z-index: 1;
    }
    @media ${device.desktopXL} {
      bottom: 138px;
    }

    &--left {
      @media ${device.tablet} {
        left: -9px;
      }
    }
    &--right {
      @media ${device.tablet} {
        right: 56%;
      }
      @media ${device.desktopLG} {
        right: 58%;
      }
    }
  }

  .react-multi-carousel-dot-list {
    @media ${device.tablet} {
      bottom: 120px;
      right: 56%;
    }
    @media ${device.desktopLG} {
      right: 58%;
    }
    @media ${device.desktopXL} {
      bottom: 146px;
    }
  }
`
