export function numberColor (number: number) {
  if (number > 0) {
    return '#009B46'
  }
  if (number < 0) {
    return '#F44E35'
  }
  return '#161616'
}

export function ArrowPosition (number: number) {
  if (number > 0) {
    return 'arrow-up'
  }
  if (number < 0) {
    return 'arrow-down'
  }
  return 'arrow-down'
}
