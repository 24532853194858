import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

export const ContainerReinvista = styled.div`

  .card-carousel {
    font-family: 'Sora';
    background-color: ${white};
    height: 276px;
    padding: 24px;
    border-radius: 16px;
    align-items: start;
    justify-content: space-between;
    border: 1px solid ${grayscale[200]};
  }

  .react-multi-carousel-track {
    padding-top: 40px;
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-dot-list {
    @media ${device.desktopLG} {
      width: 70%;
    }

    .react-multi-carousel-dot {
      button {
        background: ${orange.extra};
      }
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }
  .react-multiple-carousel__arrow--right {
    @media ${device.desktopLG} {
      right: calc(30% + 1px);
    }
  }
`

export const Button = styled.button`
  
  @media ${device.tablet} {
    max-width: 302px;
  }
  @media ${device.desktopLG} {
    max-width: 360px;
  }
`
