import React, { ChangeEvent, useEffect, useState } from 'react'
import { convertFloatToCurrencyString } from 'src/shared/helpers'
import { NumericFormat } from 'react-number-format'
import axios from 'axios'
import * as URLs from 'config/api/Urls'

import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import BtnReverse from './../../../assets/images/btn-reverse.svg'

import { SectionMoedasProps } from '../../../assets/types'
import { Calculator, Select, ListOfCoins } from './style'

export const numberToReal = (number: number) => {
  const splitedNumber = number.toFixed(6).split('.')
  splitedNumber[0] = splitedNumber[0].split(/(?=(?:...)*$)/).join('.')
  return splitedNumber.join(',')
}

const CriptoCalculator = () => {
  const [ value, setValue ] = useState(0.0)
  const [ youBuy, setYouBuy ] = useState(0.0)
  const [ moedaInicial, setMoedaInicial ] = useState(0.0)
  const [ isLimit, setIsLimit ] = useState(1)
  const [ open, setOpen ] = useState(false)
  const [ moeda, setMoeda ] = useState('BTC')
  const [ urlLogoMoeda, setURLlogoMoeda ] = useState('')
  const [ invertedCalculator, setInvertedCalculator ] = useState(false)
  const [ selectedCurrency, setSelectedCurrency ] = useState<SectionMoedasProps[]>([])
  const valorMoedaData = selectedCurrency.map((item: SectionMoedasProps) => item)
  const [ isInsert, setIsInsert ] = useState(moedaInicial)

  function currencyToFloat (currencyNumber: string) {
    const result =
    currencyNumber === ''
    ? 0
    : parseInt(currencyNumber.replace(/\D/g, '')) / 100
    return result
  }

  async function getFilter () {
    const response = await axios.get(
      `${URLs.WEB_API_LP_CRIPTO}/api/v1/cripto`,
    )
    setSelectedCurrency(response.data.moedas)
    setMoedaInicial(response.data.moedas[0].precoAtual)
  }

  function changeInput (evt: ChangeEvent<HTMLInputElement>, valorMoeda: number) {
    const inputValue = evt.target.value
    const insertValue = currencyToFloat(inputValue)
    setValue(insertValue)
    setMoedaInicial(valorMoeda)
    value > 0 && setYouBuy(insertValue / moedaInicial)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, valorMoeda: number) => {
    const inputValue = event.target.value
    const insertValue = convertFloatToCurrencyString(inputValue)
    setMoedaInicial(valorMoeda)
    setIsInsert(currencyToFloat(insertValue))

    if (isNaN(currencyToFloat(insertValue))) {
      setValue(0)
    } else {
      setValue(currencyToFloat(insertValue) * valorMoeda)
    }
  }

  const HandleClick = (isMoeda: string, isURL: string, valueSelectedCurrency?: number) => {
    setMoedaInicial(valueSelectedCurrency ?? moedaInicial)
    setMoeda(isMoeda)
    setURLlogoMoeda(isURL)
    setIsLimit(1)
    setOpen(!open)
    setYouBuy(value / (valueSelectedCurrency ?? moedaInicial))
  }

  const HandleClickInverter = (isMoeda: string, isURL: string, valueSelectedCurrency: number) => {
    setMoedaInicial(valueSelectedCurrency ?? moedaInicial)
    setIsInsert(isInsert)
    setMoeda(isMoeda)
    setURLlogoMoeda(isURL)
    setIsLimit(1)
    setOpen(!open)
    setValue(isInsert * valueSelectedCurrency)
  }

  useEffect(() => {
    getFilter()
  }, [])

  useEffect(() => {
    setOpen(open)
    setIsLimit(isLimit)
    setValue(value)
  }, [ open, isLimit, value, isInsert ])

  return (
    <>
      { !invertedCalculator
        ? (
          <Calculator className='col-12 px-0 mb-3'>
            <strong className='text-grayscale--400 fs-14 lh-17'>Você aplica</strong>
            <Select>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-6 col-lg-7 col-xl-8 pr-0 border-right d-flex justify-content-center align-items-center'>
                    <span className='text-orange--extra font-sora mr-2 fs-16 lh-20 fs-lg-20 lh-lg-25 fs-lg-24 lh-xl-30 fw-600'>R$</span>
                    <input
                      type='text'
                      name='valor'
                      className='text-orange--extra font-sora fs-16 lh-20 fs-lg-20 lh-lg-25 fs-lg-24 lh-xl-30 fw-600 noInverter'
                      maxLength={15}
                      value={convertFloatToCurrencyString(value)}
                      disabled={invertedCalculator ? true : false}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        changeInput(evt, moedaInicial)
                      }}
                    />
                  </div>
                  <div className='col-6 col-lg-5 col-xl-4 px-0'>
                    <ListOfCoins>
                      <li>
                        <OrangeDS size='MD' icon='brasil' className='mx-2' />
                        <strong className='fs-14 lh-17 text-orange--extra'>BRL</strong>
                      </li>
                    </ListOfCoins>
                  </div>
                </div>
              </div>
            </Select>
          </Calculator>
        )
        : (
          <Calculator className='col-12 px-0 order-last mb-0 mt-n3'>
            <strong className='text-grayscale--400 fs-14 lh-17'>Você recebe</strong>
            <Select>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-6 col-lg-7 col-xl-8 pr-0 border-right d-flex justify-content-center align-items-center'>
                    <span className='text-orange--extra font-sora mr-2 fs-16 lh-20 fs-lg-20 lh-lg-25 fs-lg-24 lh-xl-30 fw-600'>R$</span>
                    <input
                      type='text'
                      name='valor'
                      className='text-orange--extra font-sora fs-16 lh-20 fs-lg-20 lh-lg-25 fs-lg-24 lh-xl-30 fw-600 inverter'
                      maxLength={15}
                      value={convertFloatToCurrencyString(value)}
                      disabled={invertedCalculator ? true : false}
                    />
                  </div>
                  <div className='col-6 col-lg-5 col-xl-4 px-0'>
                    <ListOfCoins>
                      <li>
                        <OrangeDS size='MD' icon='brasil' className='mx-2' />
                        <strong className='fs-14 lh-17 text-orange--extra'>BRL</strong>
                      </li>
                    </ListOfCoins>
                  </div>
                </div>
              </div>
            </Select>
          </Calculator>
        )
      }

      <div
        className={`col-12 d-flex justify-content-center align-items-center ${invertedCalculator ? 'order-2 mt-3' : ''}`}
      >
        <img
          src={BtnReverse} alt='Ícone de Seleção de moeda' width={40}
          onClick={() => setInvertedCalculator(!invertedCalculator)}
          className='cursor-pointer'
        />
      </div>

      { !invertedCalculator
        ? (
          <Calculator className='col-12 px-0 mt-n3'>
            <strong className='text-grayscale--400 fs-14 lh-17'>Você compra</strong>
            <Select>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-6 col-lg-7 col-xl-8 border-right pr-0 d-flex justify-content-center align-items-center'>
                    <input
                      type='text'
                      name='valor'
                      className='text-orange--extra font-sora fs-16 lh-20 fs-lg-20 lh-lg-25 fs-lg-24 lh-xl-30 fw-600 noInverter'
                      maxLength={15}
                      value={invertedCalculator ? convertFloatToCurrencyString(youBuy) : numberToReal(youBuy)}
                      disabled={invertedCalculator ? false : true}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                            changeInput(evt, moedaInicial)
                      }}
                    />
                  </div>
                  <div className='col-6 col-lg-5 col-xl-4 px-0'>
                    <ListOfCoins
                      className={`cursor-pointer ${isLimit > 1 && open ? 'top-list' : ''}`}
                      onClick={() => {
                      setOpen(!open)
                      setIsLimit(100)
                      }}
                    >
                      {valorMoedaData.slice(0, 1).map((item: SectionMoedasProps, index: number) => (
                        <li key={index}>
                          <div>
                            <img
                              src={urlLogoMoeda === '' ? item.imagem.url : urlLogoMoeda}
                              alt='Ícone de Seleção de moeda'
                              width={24} className={`${item.sigla === 'ETH' ? 'mx-1' : 'mx-2'}`}
                            />
                            <strong className='fs-14 lh-17 text-orange--extra'>{moeda}</strong>
                          </div>
                        </li>
                    ))}
                      <div>
                        <OrangeDS size='MD' color='#ff7a00' icon='chevron-down' />
                      </div>
                    </ListOfCoins>
                    {isLimit > 1 &&
                      <ListOfCoins className={`${open ? 'active' : 'd-none'}`}>
                        {valorMoedaData.slice(0, isLimit).map((item: SectionMoedasProps, index: number) => (
                          <li
                            key={index} onClick={() => {
                              HandleClick(item.nome, item.imagem.url, item.precoAtual)
                            }}
                          >
                            <img src={item.imagem.url} alt='Ícone de Seleção de moeda' width={24} className='mx-2' />
                            <strong className='fs-14 lh-17 text-orange--extra'>{item.nome}</strong>
                          </li>
                      ))}
                      </ListOfCoins>
                  }
                  </div>
                </div>
              </div>
            </Select>
          </Calculator>
        )
        : (
          <Calculator className='col-12 px-0 mt-0'>
            <strong className='text-grayscale--400 fs-14 lh-17'>Você vende</strong>
            <Select>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-6 col-lg-7 col-xl-8 border-right pr-0 d-flex justify-content-center align-items-center'>
                    <NumericFormat
                      displayType='input'
                      value={youBuy}
                      fixedDecimalScale
                      decimalScale={6}
                      placeholder='1.000000'
                      maxLength={15}
                      disabled={invertedCalculator ? false : true}
                      className='text-orange--extra font-sora fs-16 lh-20 fs-lg-20 lh-lg-25 fs-lg-24 lh-xl-30 fw-600 inverter'
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(evt, moedaInicial)
                      }}
                    />
                  </div>
                  <div className='col-6 col-lg-5 col-xl-4 px-0'>
                    <ListOfCoins
                      className={`cursor-pointer ${isLimit > 1 && open ? 'top-list' : ''}`}
                      onClick={() => {
                      setOpen(!open)
                      setIsLimit(100)
                      }}
                    >
                      {valorMoedaData.slice(0, 1).map((item: SectionMoedasProps, index: number) => (
                        <li key={index}>
                          <div>
                            <img
                              src={urlLogoMoeda === '' ? item.imagem.url : urlLogoMoeda}
                              alt='Ícone de Seleção de moeda'
                              width={24} className={`${item.sigla === 'ETH' ? 'mx-1' : 'mx-2 1'}`}
                            />
                            <strong className='fs-14 lh-17 text-orange--extra'>{moeda}</strong>
                          </div>
                        </li>
                    ))}
                      <div>
                        <OrangeDS size='MD' color='#ff7a00' icon='chevron-down' />
                      </div>
                    </ListOfCoins>
                    {isLimit > 1 &&
                      <ListOfCoins className={`${open ? 'active' : 'd-none'}`}>
                        {valorMoedaData.slice(0, isLimit).map((item: SectionMoedasProps, index: number) => (
                          <li
                            key={index}
                            onClick={() => {
                              HandleClickInverter(item.nome, item.imagem.url, item.precoAtual)
                            }}
                          >
                            <img src={item.imagem.url} alt='Ícone de Seleção de moeda' width={24} className='mx-2' />
                            <strong className='fs-14 lh-17 text-orange--extra'>{item.nome}</strong>
                          </li>
                      ))}
                      </ListOfCoins>
                  }
                  </div>
                </div>
              </div>
            </Select>
          </Calculator>
        )
     }
    </>
  )
}

export default CriptoCalculator
